"use strict";

// import '../lib/smooth-scroll/smooth-scroll.polyfills.min.js';
// import $ from "jquery";
/*global  jQuery*/

$(window).on('load',function () { 
  console.log('jQuery Page Loaded.');  

  var date = new Date();
  $('.cYear').text(date.getFullYear());

  var w = $(window).width();
     var x = 768;
     if (w >= x) {
       // モーダル表示
       $(".modal-mansion").colorbox({
         rel:"mansion",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-welfare").colorbox({
         rel:"welfare",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-warehouse").colorbox({
         rel:"warehouse",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-property").colorbox({
         rel:"property",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-pickup").colorbox({
         rel:"pickup",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-other").colorbox({
         rel:"other",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-office").colorbox({
         rel:"office",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-hotel").colorbox({
         rel:"hotel",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });
       $(".modal-education").colorbox({
         rel:"education",
         width:"80%",
         height:"700px",
         maxWidth:"700px",
         maxHeight:"700px",
         returnFocus: false,
         opacity: 0.7
       });

       $("#colorbox").swipe({
        swipeLeft:function(){
          console.log('detail swipeleft');
          $.colorbox.next()
        },
        swipeRight:function(){
          console.log('detail swiperight');
          $.colorbox.prev()
        }
      });

       } else {
         $(document).on('click', 'a.card-works', function (event) {
           return false;
         });
         
       }

});


jQuery(function ($) {
$(function () {
 //読み込みが完了
 
 $(window).resize(function(){
  var w = $(window).width();
  var x = 768;
  if (w >= x) {
  // モーダル表示
  $(".modal-mansion").colorbox({
    rel:"mansion",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-welfare").colorbox({
    rel:"welfare",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-warehouse").colorbox({
    rel:"warehouse",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-property").colorbox({
    rel:"property",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-pickup").colorbox({
    rel:"pickup",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-other").colorbox({
    rel:"other",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-office").colorbox({
    rel:"office",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-hotel").colorbox({
    rel:"hotel",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });
  $(".modal-education").colorbox({
    rel:"education",
    width:"80%",
    height:"700px",
    maxWidth:"700px",
    maxHeight:"700px",
    returnFocus: false,
    opacity: 0.7
  });

  $("#colorbox").swipe({
    swipeLeft:function(){
      console.log('detail swipeleft');
      $.colorbox.next()
    },
    swipeRight:function(){
      console.log('detail swiperight');
      $.colorbox.prev()
    }
  });
  
  } else {
    $(".modal-mansion").colorbox.remove();
    $(document).on('click', 'a.card-works', function (event) {
      return false;
    });
    
  }
});


 // ページ内スムーズスクロール 
 $(document).on('click', 'a.link-scroll', function (event) {
  var href = $(this).attr("href"),
  target = $(href === "#" || href === "" ? 'html' : href);
  target.velocity("scroll", { duration: 500 });
  // target.velocity("scroll", { duration: 500, offset: -78 });
  return false;
});

 // ページ内スムーズスクロール 
 $(document).on('click', '.btn-pagetop', function (event) {
  $('body, html').animate({ scrollTop: 0 }, 500);
  // var target = $('html');
  // target.velocity("scroll", { duration: 500 });
  // target.velocity("scroll", { duration: 500, offset: -78 });
  return false;
});

   // メニュー位置調整
   $(".site-main").waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') {　//scroll down
        $(".site-header").addClass("fixed");
        $(".btn-pagetop").addClass("fixed");
    } else {
        $(".site-header").removeClass("fixed");
        $(".btn-pagetop").removeClass("fixed");
    }
  }, { offset: '0%' });


  // // モーダル表示
  // $(".modal-mansion").colorbox({
  //   rel:"mansion",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-welfare").colorbox({
  //   rel:"welfare",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-warehouse").colorbox({
  //   rel:"warehouse",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-property").colorbox({
  //   rel:"property",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-pickup").colorbox({
  //   rel:"pickup",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-other").colorbox({
  //   rel:"other",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-office").colorbox({
  //   rel:"office",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-hotel").colorbox({
  //   rel:"hotel",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });
  // $(".modal-education").colorbox({
  //   rel:"education",
  //   width:"80%",
  //   height:"700px",
  //   maxWidth:"700px",
  //   maxHeight:"700px",
  //   returnFocus: false,
  //   opacity: 0.7
  // });

});
});

//touchstartに対応してたらtouchstart、してなければclick
let mytap = window.ontouchstart===null?"touchstart":"click";
console.log('touch対応：' + mytap);

// DOM読み込み完了
document.addEventListener('DOMContentLoaded', function(e) {
  console.log('DOM Content Loaded.');

  var menubtn = document.querySelectorAll('.btn-menu')[0];
  var menu = document.querySelectorAll('.site-menu .menu')[0];
  
  var menuParent = document.getElementsByClassName('menu-item-has-children');
  for( var i = 0; i < menuParent.length; i++ ) {
    menuParent[i].insertAdjacentHTML('afterbegin','<div class="toggle-submenu close"></div>');
  }

  // var spmenubtn = document.querySelectorAll('.toggle-submenu');
  var spmenubtn = document.getElementsByClassName('toggle-submenu');
  for (let i = 0; i < spmenubtn.length; i++) {
    // fuga[i]で現在の要素を指定
    spmenubtn[i].addEventListener(mytap, function() {
      spmenubtn[i].classList.toggle('close');
      spmenubtn[i].classList.toggle('open');
    }, false);
  }

  // var scroll = new SmoothScroll('a[href*="#"]',{
  //   // header: '[data-scroll-header]'
  // });

}, false);



// 完全に読み込み完了
window.addEventListener('load', function() {
  console.log('Page Loaded.');
});
